<template>
  <div>
    <v-text-field
      v-if="!disableSearch && !searchPosBottom"
      v-model="searchProp"
      @input="updateURLParameterFilter"
      append-icon="mdi-card-search"
      label="Suchbegriff"
      placeholder="Was suchst Du?"
      solo
      style="max-width: 500px"
    ></v-text-field>

    <v-data-table
      v-model="selectedItems"
      class="elevation-2"
      :loading="preloader"
      loading-text="Loading... Please wait"
      :headers="headers"
      :items="items"
      :page.sync="options.page"
      :options.sync="options"
      :search="searchProp"
      :show-select="isSelectable ? true : false"
      :disable-pagination="disablePagination"
      :hide-default-footer="disablePagination"
      :stateKey="stateKey"
      :item-class="itemRowBackground"
      @update:page="updateURLParameter"
      @update:items-per-page="updateURLParameterItemsPerPage"
      @page-count="updateTableOptions"
      @dblclick:row="doubleClick"
      @input="emitSelection"
      @toggle-select-all="emitSelection"
    >
      <template v-slot:[`item.productsList`]="{ item }">
        <div v-html="item.productsList"></div>
      </template>

      <!-- 1/0 = Ja(Nein) -->

      <template v-slot:[`item.notificationWhenLead`]="{ item }">
        <span v-if="item.notificationWhenLead === '0'">Nein</span>
        <span v-else>Ja</span>
      </template>

      <template v-slot:[`item.isDecider`]="{ item }">
        <span v-if="item.isDecider === '0'">Nein</span>
        <span v-else>Ja</span>
      </template>

      <template v-slot:[`item.isCeo`]="{ item }">
        <span v-if="item.isCeo === '0'">Nein</span>
        <span v-else>Ja</span>
      </template>

      <template v-slot:[`item.isActive`]="{ item }">
        <span v-if="item.isActive === '0'">Nein</span>
        <span v-else>Ja</span>
      </template>

      <template v-slot:[`item.isDemoRequestable`]="{ item }">
        <span v-if="item.isDemoRequestable === '0'">Nein</span>
        <span v-else>Ja</span>
      </template>

      <template v-slot:[`item.isJmp`]="{ item }">
        <span v-if="item.isJmp === '0'">Nein</span>
        <span v-else>Ja</span>
      </template>

      <template v-slot:[`item.gender`]="{ item }">
        <span v-if="item.gender === 'male'">Herr</span>
        <span v-else-if="item.gender === 'female'">Frau</span>
        <span v-else-if="item.gender === 'divers'">Divers</span>
        <span v-else>-</span>
      </template>

      <template v-slot:[`item.description`]="{ item }">
        <vue-markdown :source="item.description"></vue-markdown>
      </template>

      <template v-slot:[`item.created`]="{ item }">
        {{ formatDate(item.created) }}
      </template>

      <template v-slot:[`item.updated`]="{ item }">
        <span v-if="item.updated">
          {{ formatDate(item.updated) }}
        </span>
      </template>

      <template v-slot:[`item.dateEnd`]="{ item }">
        <span v-if="item.dateEnd">
          {{ formatDateNoTime(item.dateEnd) }}
        </span>
        <span v-else> offen </span>
      </template>

      <template v-slot:[`item.dateStart`]="{ item }">
        <span v-if="item.dateStart">
          {{ formatDateNoTime(item.dateStart) }}
        </span>
      </template>

      <template v-slot:[`item.startYear`]="{ item }">
        {{ item.startYear }}/{{ parseInt(item.startYear) + 1 }}
      </template>

      <template v-slot:[`item.pitchState`]="{ item }">
        <TablePitchState :item="item" />
      </template>

      <template v-slot:[`item.state`]="{ item }">
        <CampaignSetupState :item="item" :key="stateKey" />
      </template>

      <template v-slot:[`item.productsObject`]="{ item }">
        <TableProductsObject :item="item" />
      </template>

      <template v-slot:[`item.orderCompanyById`]="{ item }">
        <span v-if="item && item.companies_id">
          <CompanyNameById :id="item.companies_id" />
        </span>
      </template>

      <template v-slot:[`item.quickLeadStatistic`]="{ item }">
        <span v-if="item && item.id">
          <QuickCampaignStatistics
            :id="item.id"
            :live="item.status === 'active' ? true : false"
          />
        </span>
      </template>

      <template v-slot:[`item.leadsCount`]="{ item }">
        <span v-if="!item.leadsCount && item.leadsCount !== 0">
          <v-progress-circular
            :size="20"
            :width="3"
            indeterminate
            color="primary"
          ></v-progress-circular>
        </span>
        <span v-else>
          {{ item.leadsCount }}
        </span>
      </template>

      <template v-slot:[`item.status`]="{ item }">
        <span v-if="item && item.id">
          <v-chip v-if="item.status === 'paused'" color="warning">
            Pausiert
          </v-chip>
          <v-chip v-else-if="item.status === 'waiting'" color="success">
            Wartet
          </v-chip>
          <v-chip v-else-if="item.status === 'inactive'" color="warning">
            Offen
          </v-chip>
          <v-chip v-else-if="item.status === 'pending'" color="light-green">
            <v-progress-circular
              :width="3"
              :size="20"
              color="white"
              indeterminate
            ></v-progress-circular>
            <span class="ml-2 white--text">Pending</span>
          </v-chip>
          <v-chip v-else-if="item.status === 'completed'" color="success">
            Fertig
          </v-chip>
          <v-chip v-else-if="item.status === 'active'" color="success">
            Aktiv
          </v-chip>
          <v-chip v-else-if="item.status === 'blocked'" color="warning">
            Blockiert
          </v-chip>
          <v-chip v-else-if="item.status === 'open'" color="warning">
            Offen
          </v-chip>
          <v-chip v-else-if="item.status === 'sleeping'" color="light-gray">
            Ruht
          </v-chip>

          <v-chip v-else color="white"> - </v-chip>
        </span>
      </template>

      <template v-slot:[`item.isTokenValid`]="{ item }">
        <v-icon v-if="item.isTokenValid" large color="success">
          mdi-check-circle
        </v-icon>

        <v-icon v-else large color="error"> mdi-alert-rhombus </v-icon>
      </template>

      <template v-slot:[`item.checkFacebookToken`]="{ item }">
        <v-btn
          class="ma-2"
          color="primary"
          @click="$emit('checkFacebookToken', item)"
        >
          Check Token
        </v-btn>
      </template>

      <template v-slot:[`item.validFbToken`]="{ item }">
        <span v-if="item && item.isTokenValid">
          <v-icon v-if="item.isTokenValid === '1'" large color="success">
            mdi-checkbox-blank-circle
          </v-icon>

          <v-icon v-else-if="item.isTokenValid === '0'" large color="error">
            mdi-checkbox-blank-circle
          </v-icon>

          <v-icon v-else large color="white">
            mdi-checkbox-blank-circle
          </v-icon>
        </span>
      </template>

      <template v-slot:[`item.cpType`]="{ item }">
        <v-icon
          v-if="item.notificationWhenLead === '1'"
          color="indigo"
          class="ma-1"
        >
          mdi-message-plus
        </v-icon>

        <v-icon v-else color="grey lighten-2" class="ma-1">
          mdi-message-plus
        </v-icon>

        <v-icon
          v-if="item.getsTechnicalNotifications === '1'"
          color="indigo"
          class="ma-1"
        >
          mdi-message-cog
        </v-icon>

        <v-icon v-else color="grey lighten-2" class="ma-1">
          mdi-message-cog
        </v-icon>

        <v-icon v-if="item.hasNewsletter === '1'" color="indigo" class="ma-1">
          mdi-message-text
        </v-icon>

        <v-icon v-else color="grey lighten-2" class="ma-1">
          mdi-message-text
        </v-icon>

        <v-icon v-if="item.isCeo === '1'" color="green" class="ma-1">
          mdi-crown
        </v-icon>

        <v-icon v-else color="grey lighten-2" class="ma-1"> mdi-crown </v-icon>

        <v-icon v-if="item.isDecider === '1'" color="green" class="ma-1">
          mdi-account-tie-voice
        </v-icon>

        <v-icon v-else color="grey lighten-2" class="ma-1">
          mdi-account-tie-voice
        </v-icon>

        <v-icon
          v-if="item.isDataProtectionOfficer === '1'"
          color="pink"
          class="ma-1"
        >
          mdi-security
        </v-icon>

        <v-icon v-else color="grey lighten-2" class="ma-1">
          mdi-security
        </v-icon>

        <v-icon
          v-if="item.isContentResponsibility === '1'"
          color="pink"
          class="ma-1"
        >
          mdi-hand-heart
        </v-icon>

        <v-icon v-else color="grey lighten-2" class="ma-1">
          mdi-hand-heart
        </v-icon>
      </template>

      <template v-slot:[`item.planMonths_id`]="{ item }">
        <v-chip
          small
          v-if="item.planMonths_id"
          color="teal"
          class="white--text"
          title="Jahresmarketing"
        >
          JMP
        </v-chip>
        <v-chip
          small
          v-else
          color="blue-grey"
          class="white--text"
          title="Direktkauf"
        >
          DK
        </v-chip>
      </template>

      <template v-slot:[`item.isJmpProduct`]="{ item }">
        <v-chip
          small
          v-if="item.planMonths_id"
          color="teal"
          class="white--text"
          title="Jahresmarketing"
        >
          JMP
        </v-chip>

        <template v-else>
          <v-chip
            v-if="item.contextJmp === '1'"
            small
            color="teal"
            class="white--text"
            title="Jahresmarketing Plus"
          >
            JMP+
          </v-chip>

          <v-chip
            small
            v-else
            color="blue-grey"
            class="white--text"
            title="Direktkauf"
          >
            DK
          </v-chip>
        </template>
      </template>

      <template v-slot:[`item.campaignType`]="{ item }">
        <span v-if="item">
          <v-icon
            v-if="item.facebookLeadAds === '1'"
            color="blue"
            title="Facebook LeadAD"
          >
            mdi-facebook
          </v-icon>
          <v-icon
            v-else-if="item.hasMitfitMember === '1'"
            color="purple"
            title="mitfitMember"
          >
            mdi-alpha-m-circle
          </v-icon>
          <v-icon
            v-else-if="item.hasMitfitPay === '1'"
            color="pink"
            title="mitfitPay"
          >
            mdi-alpha-p-circle
          </v-icon>
          <v-icon v-else color="primary" title="Kampagne">
            mdi-alpha-c-circle
          </v-icon>
        </span>
      </template>

      <template v-slot:[`item.mediaUuid`]="{ item }">
        <MediaItemPreview
          v-if="item.mediaUuid"
          :uuid="item.mediaUuid"
          :showCaption="false"
          aspectRatio="1"
          width="100"
          class="my-1"
          :key="item.mediaUuid"
        />
      </template>

      <template v-slot:[`item.personQuote`]="{ item }">
        <vue-markdown
          v-if="item.personQuote"
          :source="item.personQuote"
        ></vue-markdown>
      </template>

      <template v-slot:[`item.adminName`]="{ item }">
        <span>{{ item.userFirstname }} {{ item.userLastname }}</span>
      </template>

      <template v-slot:[`item.note`]="{ item }">
        <div class="pt-4 pb-4">
          <span v-html="item.note"></span>
        </div>
      </template>

      <template v-slot:[`item.actionIcon`]="{ item }">
        <span v-html="item.actionIcon"></span>
      </template>

      <template v-slot:[`item.summary`]="{ item }">
        <span v-html="item.summary"></span>
      </template>

      <template v-slot:[`item.pages_id`]="{ item }">
        <span v-if="item.pages_id">
          {{ item.pages_id }}
          <!-- wenn ede soweit ist, hier der pagename -->
        </span>
      </template>

      <template v-slot:[`item.branches`]="{ item }">
        <span v-if="item.branches">
          <span v-if="Array.isArray(item.branches)">
            <v-chip v-for="item in item.branches" :key="item.id" class="ma-1">
              {{ item.name }}
            </v-chip>
          </span>
        </span>
      </template>

      <!-- this.$router.push({ name: 'sales.products.:id', params: { id: value.itemId } }) -->

      <template v-slot:[`item.companyName`]="{ item }">
        <span v-if="item.companies_id">
          <a
            @click="
              $router.push({
                name: 'csm.company-manager.:id',
                params: { id: item.companies_id },
              })
            "
            >{{ item.companyName }}</a
          >
        </span>
        <span v-else>{{ item.companyName }}</span>
      </template>

      <!-- Action-Buttons -->
      <template v-slot:[`item.actions`]="{ item, index }">
        <TableActionButtons
          v-if="itemActions"
          :item="item"
          :itemActions="itemActions"
          :index="index"
          @action="$emit('tableAction', $event)"
        />
      </template>
    </v-data-table>

    <v-text-field
      v-if="!disableSearch && searchPosBottom"
      background-color="grey lighten-3"
      v-model="searchProp"
      append-icon="mdi-card-search"
      label="Suchbegriff"
      placeholder="Was suchst Du?"
      class="mt-4"
      solo
      style="max-width: 500px"
    ></v-text-field>
  </div>
</template>

<script>
import TablePitchState from "@/components/ui/TablePitchState.vue";
import TableActionButtons from "@/components/ui/TableActionButtons.vue";
import TableProductsObject from "@/components/ui/TableProductsObject.vue";
import CompanyNameById from "@/components/ui/CompanyNameById.vue";
import CampaignSetupState from "@/components/campaign_management/CampaignSetupState.vue";
import MediaItemPreview from "@/components/mediapool/MediaItemPreview";
import VueMarkdown from "vue-markdown/src/VueMarkdown";
import QuickCampaignStatistics from "@/components/campaign_management/QuickCampaignStatistics.vue";

export default {
  name: "DataTable",
  props: [
    "headers",
    "items",
    "itemActions",
    "search",
    "preloader",
    "isSelectable",
    "disableDoubleClick",
    "disableSearch",
    "searchPosBottom",
    "optionsBranches",
    "disableItemsPerPage",
    "disablePagination",
  ],

  // watch items mediaKey++

  components: {
    TablePitchState,
    TableActionButtons,
    CampaignSetupState,
    TableProductsObject,
    MediaItemPreview,
    VueMarkdown,
    CompanyNameById,
    QuickCampaignStatistics,
  },

  data() {
    return {
      options: {
        itemsPerPage: 10,
        page: 1,
      },
      searchProp: this.search,
      toggle_exclusive: undefined,
      paramPage: 1,
      selectedItems: [],
      stateKey: 0,
    };
  },

  // watch items on change

  watch: {
    items: {
      handler: function () {
        // refresh data-table
        this.stateKey++;
      },
      deep: true,
    },
  },

  methods: {
    itemRowBackground: function (item) {
      return item.isTest === "1" ? "test-item" : "";
    },

    doubleClick(event, { item, index }) {
      if (!this.disableDoubleClick) {
        this.$emit("tableAction", {
          action: "editItem",
          itemId: item.id,
          itemIndex: index,
          itemData: item,
        }); // emit to parent-component
      }
    },

    updateTableOptions() {
      /* after event: @page-count 
      Set settings to the options-json when options-json was generated
      */

      const params = new URLSearchParams(window.location.search); // get url

      if (params.has("page")) {
        this.options.page = parseInt(params.get("page"));
      }

      if (params.has("itemsPerPage")) {
        this.options.itemsPerPage = parseInt(params.get("itemsPerPage"));
      }

      if (params.has("filter")) {
        this.searchProp = params.get("filter");
      }
    },

    updateURLParameter(page) {
      if (parseInt(this.$route.query.page) !== page) {
        this.$router.push({
          query: Object.assign({}, this.$route.query, { page: page }),
        });
      }
    },

    updateURLParameterItemsPerPage(itemsPerPage) {
      if (parseInt(this.$route.query.itemsPerPage) !== itemsPerPage) {
        this.$router.push({
          query: Object.assign({}, this.$route.query, {
            itemsPerPage: itemsPerPage,
          }),
        });
      }
    },

    updateURLParameterFilter(filter) {
      if (parseInt(this.$route.query.filter) !== filter) {
        this.$router.push({
          query: Object.assign({}, this.$route.query, {
            filter: filter,
          }),
        });
      }
    },

    addZero(i) {
      if (i < 10) {
        i = "0" + i;
      }
      return i;
    },

    formatDate(date) {
      const dt = new Date(date);
      const d = this.addZero(dt.getDate()); //Month from 0 to 11
      const m = this.addZero(dt.getMonth() + 1); //Month from 0 to 11
      const y = dt.getFullYear();
      const h = this.addZero(dt.getHours());
      const i = this.addZero(dt.getMinutes());

      const niceDate = d + "." + m + "." + y + " " + h + ":" + i + " Uhr";

      if (!isNaN(dt)) {
        return niceDate;
      } else {
        return date;
      }
    },

    formatDateNoTime(date) {
      const dt = new Date(date);
      const d = this.addZero(dt.getDate()); //Month from 0 to 11
      const m = this.addZero(dt.getMonth() + 1); //Month from 0 to 11
      const y = dt.getFullYear();

      const niceDate = d + "." + m + "." + y;

      if (!isNaN(dt)) {
        return niceDate;
      } else {
        return date;
      }
    },

    emitSelection(items) {
      /* passing selected items back to parent component.
        In parent component a prop got updated with this selection */

      this.$emit("tableAction", {
        action: "itemsSelected",
        items: items,
      });
    },
  },

  computed: {
    buyerName() {
      return "Hallo";
    },
  },
};
</script>

<style>
.test-item {
  background-color: rgb(0, 0, 0, 0.05);
  color: lightgray;
}
</style>
